<template>
  <div class="modal-body feedback-reply-modal">
    <div v-if="isQuestionOne && !isQuestionTwo && !isQuestionThree">
      <div class="col-md-12 mb-3">
        <label class="form-label">Can we close feedback?<span></span></label>
      </div>
      <a
        class="btn small-cta"
        href="javascript:void(0);"
        @click="showQuestionTwo"
        ref="closeContainer"
      >
        Yes
      </a>
      <a
        class="btn small-cta"
        href="javascript:void(0);"
        @click="updateStatus"
        ref="dormantContainer"
      >
        No
      </a>
    </div>
    <div v-if="!isQuestionOne && isQuestionTwo && !isQuestionThree">
      <div class="col-md-12 mb-3">
        <label>
          If you are happy with us, can you kindly refer us to a friend or
          family?</label
        >
      </div>
      <a
        class="btn small-cta"
        href="javascript:void(0);"
        @click="showQuestionThree"
      >
        Yes
      </a>
      <a
        class="btn small-cta"
        href="javascript:void(0);"
        @click="$emit('closeModal')"
      >
        No
      </a>
    </div>
    <div v-if="!isQuestionOne && !isQuestionTwo && isQuestionThree">
      <div class="col-md-12">
        <div
          v-for="(input, index) in references"
          :key="`phoneInput-${index}`"
          class="input wrapper flex items-center"
        >
          <div class="d-flex justify-content-center align-item-center">
            <h6 style="margin-right: 5px">Reference {{ index + 1 }}</h6>
            <!--          Remove Svg Icon-->
            <svg
              v-show="references.length > 1"
              @click="removeField(index)"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
              class="ml-2 cursor-pointer"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                fill="#EC4899"
                d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"
              />
            </svg>
          </div>
          <div class="col-md-6">
            <p class="label text-left">Name</p>
            <input
              v-model="input.name"
              type="text"
              class="form-control"
              placeholder="Name"
            />
          </div>

          <div class="col-md-6">
            <p class="label text-left">Country Code</p>
            <Multiselect
              v-model="input.country_code"
              v-bind="countryCodeOptions"
            />
          </div>
          <div class="col-md-6">
            <p class="label text-left">Phone</p>
            <input
              v-model="input.phone"
              type="text"
              class="form-control"
              placeholder="Phone"
            />
          </div>
        </div>
      </div>
      <!--          Add Svg Icon-->
      <div class="d-flex justify-content-between">
        <a
          class="btn small-cta m-0"
          href="javascript:void(0);"
          @click="saveStudentReferences"
          ref="referencesContainer"
        >
          Save
        </a>
        <svg
          @click="addField"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
          class="ml-2 cursor-pointer"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            fill="green"
            d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import FeedbackService from "@/services/FeedbackService";
import CommonService from "@/services/CommonService";
import useVuelidate from "@vuelidate/core";
import {
  required,
  // requiredIf,
  numeric,
  maxLength,
  minLength,
  helpers,
} from "@vuelidate/validators";
import Multiselect from "@vueform/multiselect";

export default {
  name: "FeedbackClosedForm",
  components: {
    Multiselect,
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      // feedbackReplyLoading: true,
      feedbackId: "",
      isQuestionOne: true,
      isQuestionTwo: false,
      isQuestionThree: false,
      countryCode: "",
      fullPage: true,
      countryCodeOptions: {
        value: null,
        options: [],
        placeholder: "Select Country Code",
        canDeselect: false,
        loading: true,
        // required: true,
        searchable: true,
        disabled: false,
      },
      references: [
        {
          name: "",
          country_code: "",
          phone: "",
        },
      ],
    };
  },
  validations() {
    return {
      countryCodeOptions: {
        value: {
          required: helpers.withMessage("Please select country code", required),
        },
      },
      name: {
        required: helpers.withMessage("Please enter phone no.", required),
      },
      phone_no: {
        required: helpers.withMessage("Please enter name.", required),
        numeric: helpers.withMessage(
          "Please enter a valid contact no.",
          numeric
        ),
        maxLength: helpers.withMessage(
          "Max of 15 digits allowed",
          maxLength(15)
        ),
        minLength: helpers.withMessage(
          "It should be atleast of 6 digits",
          minLength(6)
        ),
      },
    };
  },

  methods: {
    addField() {
      this.references.push({
        name: "",
        country_code: "+91",
        phone: "",
      });
    },
    removeField(index) {
      this.references.splice(index, 1);
    },
    showQuestionTwo() {
      let loader = this.$loading.show({
        // Optional parameters
        container: this.fullPage ? null : this.$refs.closeContainer,
      });

      let params = {
        feedback_id:
          this.$route.params.feedback_id != undefined
            ? this.$route.params.feedback_id
            : "",
        status: "CLOSED",
      };
      FeedbackService.updateFeedbackStatus(params)
        .then((response) => {
          loader.hide();
          // console.log(response.data);
          if (response.data.status === "SUCCESS") {
            this.$toast.success(response.data.message);
            // this.$emit("closeModal");
            this.$emit("feedbackClosed");
            this.isQuestionOne = false;
            this.isQuestionTwo = !this.isQuestionTwo;
            this.isQuestionThree = false;
          }
          if (response.data.status === "ERROR") {
            this.$toast.error(response.data.message);
          }
          if (response.data.status === "VALIDATION_FAILED") {
            // console.log(response.data.errors);
            let errorsObject = response.data.errors;
            // Note:- Object.values gives the values as an array
            let errorValuesArray = Object.values(errorsObject);

            if (errorValuesArray.length > 0) {
              for (let index = 0; index < errorValuesArray.length; index++) {
                this.$toast.error(errorValuesArray[index]);
                // console.log(errorValuesArray[index]);
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    showQuestionThree() {
      this.isQuestionOne = false;
      this.isQuestionTwo = false;
      this.isQuestionThree = !this.isQuestionThree;
      this.allCountries();
    },
    updateStatus() {
      let loader = this.$loading.show({
        // Optional parameters
        container: this.fullPage ? null : this.$refs.dormantContainer,
      });

      let params = {
        feedback_id:
          this.$route.params.feedback_id != undefined
            ? this.$route.params.feedback_id
            : "",
        status: "DORMANT",
      };
      FeedbackService.updateFeedbackStatus(params)
        .then((response) => {
          loader.hide();
          // console.log(response.data);
          if (response.data.status === "SUCCESS") {
            this.$toast.success(response.data.message);
            this.$emit("closeModal");
            // trigger the function declared inside the FeedbackSlot component
          }
          if (response.data.status === "ERROR") {
            // this.$toast.error(response.data.message);
          }
          if (response.data.status === "VALIDATION_FAILED") {
            // console.log(response.data.errors);
            let errorsObject = response.data.errors;
            // Note:- Object.values gives the values as an array
            let errorValuesArray = Object.values(errorsObject);

            if (errorValuesArray.length > 0) {
              for (let index = 0; index < errorValuesArray.length; index++) {
                this.$toast.error(errorValuesArray[index]);
                // console.log(errorValuesArray[index]);
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async allCountries() {
      await CommonService.getAllCountries()
        .then((response) => {
          // console.log(response.data);
          if (response.data.status === "SUCCESS") {
            this.countryCodeOptions.options = []; // to clear previously loaded options
            this.countryCodeOptions.loading = false;
            let countries = response.data.countries;
            if (countries.length > 0) {
              countries.map((country) => {
                let options = {
                  value: country.phone_code,
                  label: country.country_name + " (" + country.phone_code + ")",
                };
                this.countryCodeOptions.options.push(options);
              });
              this.countryCodeOptions.value = "+91";
              this.references[0].country_code = this.countryCodeOptions.value;
            }
            // console.log(this.countryCodeOptions);
          }
          if (response.data.status === "ERROR") {
            this.$toast.error(response.data.message);
          }
          if (response.data.status === "VALIDATION_FAILED") {
            // console.log(response.data.errors);
            let errorsObject = response.data.errors;
            // Note:- Object.values gives the values as an array
            let errorValuesArray = Object.values(errorsObject);

            if (errorValuesArray.length > 0) {
              for (let index = 0; index < errorValuesArray.length; index++) {
                this.$toast.error(errorValuesArray[index]);
                // console.log(errorValuesArray[index]);
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    saveStudentReferences() {
      let loader = this.$loading.show({
        // Optional parameters
        container: this.fullPage ? null : this.$refs.referencesContainer,
      });

      let params = {
        references: this.references,
        feedback_id:
          this.$route.params.feedback_id != undefined
            ? this.$route.params.feedback_id
            : "",
      };
      FeedbackService.saveReferences(params)
        .then((response) => {
          loader.hide();
          // console.log(response.data);
          if (response.data.status === "SUCCESS") {
            this.$toast.success(response.data.message);
            this.$emit("closeModal");
            // trigger the function declared inside the FeedbackSlot component
          }
          if (response.data.status === "ERROR") {
            this.$toast.error(response.data.message);
          }
          if (response.data.status === "VALIDATION_FAILED") {
            // console.log(response.data.errors);
            let errorsObject = response.data.errors;
            // Note:- Object.values gives the values as an array
            let errorValuesArray = Object.values(errorsObject);

            if (errorValuesArray.length > 0) {
              for (let index = 0; index < errorValuesArray.length; index++) {
                this.$toast.error(errorValuesArray[index]);
                // console.log(errorValuesArray[index]);
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<style lang="scss">
@import "@/style/discussion-board.scss";
</style>
