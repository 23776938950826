<template>
  <div>
    <div class="discussion-board">
      <div class="subnav-wrapper">
        <div class="container">
          <router-link :to="{ name: 'MyFeedback' }" class="btn small-cta">
            <img src="../assets/arrow.svg" alt="" /> Back to feedback
          </router-link>
        </div>
      </div>
      <div class="container">
        <div class="outer-header"></div>
        <div class="info-section">
          <div class="_section-head">
            <div v-if="feedbackReplyLoading" class="loading">Loading...</div>
            <h6 v-if="!feedbackReplyLoading">{{ feedbackData.feedback }}</h6>
            <ul
              v-if="!feedbackReplyLoading"
              class="
                d-flex
                flex-row
                justify-content-between
                w-100
                list-unstyled
              "
            >
              <li>Category : {{ feedbackData.category_title }}</li>
              <li>Rating : {{ feedbackData.rating }}</li>
              <li>Status : {{ feedbackData.status }}</li>
            </ul>
          </div>
          <div v-if="!feedbackReplyLoading" class="p-3 text-end">
            <a
              href="javascript:void(0);"
              class="btn small-cta"
              data-bs-target="#feedbackStatusModal"
              aria-haspopup="true"
              data-bs-toggle="modal"
              @click="clearData"
              ref="openModal"
              v-if="!isFeedbackClosed"
              >Update</a
            >
          </div>

          <div
            class="modal fade"
            id="feedbackStatusModal"
            tabindex="-1"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-lg">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Update</h5>
                  <button
                    type="button"
                    class="btn-close"
                    ref="closeModal"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <FeedbackSatisfiedForm
                  v-if="isClosedStatus"
                  @showFeedbackCloseForm="toggleFeedbackForm"
                  @closeModal="$refs.closeModal.click()"
                />
                <FeedbackCloseForm
                  v-if="!isClosedStatus"
                  @closeModal="$refs.closeModal.click()"
                  @feedbackClosed="isFeedbackClosed = true"
                />
              </div>
            </div>
          </div>

          <FeedbackSlot ref="FeedbackSlot" :togglePostCount="false" />
          <div ref="reply_form"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FeedbackSlot from "@/components/FeedbackSlot.vue";
import FeedbackSatisfiedForm from "@/components/FeedbackSatisfiedForm.vue";
import FeedbackCloseForm from "@/components/FeedbackCloseForm.vue";
import FeedbackService from "@/services/FeedbackService";

export default {
  name: "FeedbackView",
  components: {
    FeedbackSlot,
    FeedbackSatisfiedForm,
    FeedbackCloseForm,
  },

  data() {
    return {
      feedbackReplyLoading: true,
      feedbackId: "",
      isClosedStatus: true,
      isFeedbackClosed: false,
      feedbackData: "",
    };
  },
  mounted() {
    this.getFeedback();
  },
  methods: {
    toggleFeedbackForm() {
      this.isClosedStatus = !this.isClosedStatus;
      let params = {
        feedback_id:
          this.$route.params.feedback_id != undefined
            ? this.$route.params.feedback_id
            : "",
        status: "SATISFIED",
      };
      FeedbackService.updateFeedbackStatus(params)
        .then((response) => {
          // console.log(response.data);
          if (response.data.status === "SUCCESS") {
            // this.$toast.success(response.data.message);
            // trigger the function declared inside the FeedbackSlot component
          }
          if (response.data.status === "ERROR") {
            // this.$toast.error(response.data.message);
          }
          if (response.data.status === "VALIDATION_FAILED") {
            // console.log(response.data.errors);
            let errorsObject = response.data.errors;
            // Note:- Object.values gives the values as an array
            let errorValuesArray = Object.values(errorsObject);

            if (errorValuesArray.length > 0) {
              for (let index = 0; index < errorValuesArray.length; index++) {
                this.$toast.error(errorValuesArray[index]);
                // console.log(errorValuesArray[index]);
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getFeedback: function () {
      let params = {
        feedback_id:
          this.$route.params.feedback_id != undefined
            ? this.$route.params.feedback_id
            : "",
      };
      FeedbackService.getFeedback(params)
        .then((result) => {
          if (result.data.status == "SUCCESS") {
            this.feedbackReplyLoading = false;
            this.feedbackData = result.data.feedback;
            if (this.feedbackData.status == "CLOSED") {
              this.isFeedbackClosed = true;
            }
          } else if (result.data.status == "ERROR") {
            this.$toast.error(result.data.message);
            // this.$refs.closeModal.click();
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    clearData() {
      this.isClosedStatus = true;
    },
  },
};
</script>

<style lang="scss">
@import "@/style/discussion-board.scss";
</style>
