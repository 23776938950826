<template>
  <div class="modal-body feedback-reply-modal">
    <div class="col-md-12 mb-3">
      <label class="form-label"
        >Are you satisfied with the response?<span></span
      ></label>
    </div>
    <a
      class="btn small-cta"
      href="javascript:void(0);"
      @click.prevent="$emit('showFeedbackCloseForm')"
    >
      Yes
    </a>
    <a
      class="btn small-cta"
      href="javascript:void(0);"
      @click="updateStatus"
      ref="unsatisfiedContainer"
    >
      No
    </a>
  </div>
</template>

<script>
import FeedbackService from "@/services/FeedbackService";
export default {
  name: "FeedbackSatisfiedForm",
  data() {
    return {
      fullPage: true,
    };
  },
  methods: {
    updateStatus() {
      let loader = this.$loading.show({
        // Optional parameters
        container: this.fullPage ? null : this.$refs.unsatisfiedContainer,
      });

      let params = {
        feedback_id:
          this.$route.params.feedback_id != undefined
            ? this.$route.params.feedback_id
            : "",
        status: "UNSATISFIED",
      };
      FeedbackService.updateFeedbackStatus(params)
        .then((response) => {
          loader.hide();
          // console.log(response.data);
          if (response.data.status === "SUCCESS") {
            this.$toast.success(response.data.message);
            // trigger the function declared inside the FeedbackSlot component
            this.$emit("closeModal");
          }
          if (response.data.status === "ERROR") {
            this.$emit("closeModal");
            // this.$toast.error(response.data.message);
          }
          if (response.data.status === "VALIDATION_FAILED") {
            // console.log(response.data.errors);
            let errorsObject = response.data.errors;
            // Note:- Object.values gives the values as an array
            let errorValuesArray = Object.values(errorsObject);

            if (errorValuesArray.length > 0) {
              for (let index = 0; index < errorValuesArray.length; index++) {
                this.$toast.error(errorValuesArray[index]);
                // console.log(errorValuesArray[index]);
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<style lang="scss">
@import "@/style/login.scss";
</style>
<style src="@vueform/toggle/themes/default.css"></style>
